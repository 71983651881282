/* CSS variables with new color palette */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;700&display=swap');

:root {
  --dark-green: #2C5F2D;
  --tannish-green: #a0a17f;
  --light-tan: #F3F2EE;
  --accent-orange: #E38B29;
  --black: #000000;
  --white: #ffffff;
  --header-gradient-start: #a0a17f;
  --header-gradient-end: #2C5F2D;
  --page-gradient-start: #F3F2EE;
  --page-gradient-end: #E5D0B1;
  --pleasant-beige: #f8f3e0; /* Updated to a lighter beige */
}

/* Header styles with gradient */
header {
  background: linear-gradient(to right, var(--header-gradient-start), var(--header-gradient-end));
  color: var(--white);
  padding: 20px;
  text-align: center;
}

/* Global styles */
body {
  background-image: url('./images/rock-texture-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.9); /* Light color in case image doesn't load */
  color: var(--black);
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}


/* Example of using a texture background for header or any section */
header {
  background: linear-gradient(to right, #a0a17f, #8fa17c); /* Softer gradient */
  color: var(--white);
  padding: 20px;
  text-align: center;
}

/* Applying the new palette to elements */
h1, h2, h3, .company-name, .menu-link {
  font-family: 'Cinzel', serif;
  font-weight: 700;
  color: var(--dark-green); /* Apply dark green to headers */
}


/* App container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

/* Fade-in effect */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

html {
  scroll-behavior: smooth;
}

/* Global Card Styles in index.css */

/* Remove aspect-ratio from .card */
.card {
  background-color: var(--tannish-green);
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  color: var(--black);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
}

/* Apply aspect-ratio to .card-image-container */
.card-image-container {
  width: 100%;
  aspect-ratio: 4 / 3;
  background-color: var(--pleasant-beige); /* Set the background to pleasant beige */
  overflow: hidden;
}

/* Ensure the image fills the container */
.card-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the full image is visible */
  display: block;
}

/* Adjust .card-title */
.card-title {
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  background-color: var(--tannish-green);
  letter-spacing: 1px;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title span {
  display: inline-block;
  max-width: 90%; /* Limit text width to 90% of the parent */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}




.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: var(--light-green);
  color: var(--dark-green);
}

.card:hover .card-title span {
  color: var(--dark-green); /* Use the same subtle dark green */
}


/* Back Button */
.back-button {
  display: inline-block;
  color: var(--black);
  text-decoration: none;
  padding: 10px 20px;
  background-color: var(--tannish-green);
  border-radius: 5px;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: var(--dark-green);
  color: var(--light-tan);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust for smaller screens */
  }
}