/* src/BrowseTrailers.css */

.browse-trailers-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px 40px;
  opacity: 0;
  transform: translateY(20px); /* Slight initial movement for more natural feel */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Fade-in transition */
}

/* This class will be added to the container after mounting */
.fade-in {
  opacity: 1;
  transform: translateY(0); /* Reset the movement */
}

/* Other styles remain the same... */
.page-title {
  font-size: 48px;
  color: var(--dark-green);
  margin-bottom: 30px;
  text-align: center;
  font-family: 'Cinzel', serif;
  font-weight: 700;
  border-bottom: 3px solid var(--dark-green);
  padding-bottom: 10px;
}

.categories-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.categories-section {
  flex-basis: 45%;
  margin-bottom: 40px;
}

.section-title {
  font-size: 32px;
  color: var(--dark-green);
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Cinzel', serif;
  font-weight: 700;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}
