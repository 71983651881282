/* src/ContactUs.css */

.contact-us-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center; /* Center all text in the container */
}

.contact-us-container h1 {
  font-family: 'Cinzel', serif;
  color: var(--dark-green);
  margin-bottom: 30px;
}

.contact-details, .hours-section, .address-section {
  font-size: 18px;
  margin-bottom: 30px;
}

.contact-details p, .hours-section p, .address-section p {
  margin: 10px 0;
}

.map-container {
  text-align: center;
  margin-bottom: 30px;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
}
