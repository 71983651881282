/* src/TrailerDetail.css */
.trailer-detail-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center; /* Center all text */
  }
  
  .back-button-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    display: inline-block;
    color: var(--black);
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--tannish-green);
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: var(--dark-green);
    color: var(--light-tan);
  }
  
  /* Update the trailer title */
  .trailer-title {
    font-size: 32px;
    color: var(--dark-green);
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
  }

  .trailer-title, .trailer-details h3 {
    text-align: center;
  }
  
  .trailer-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .trailer-details h3 {
    font-size: 24px;
    margin-top: 20px;
    color: var(--tannish-green);
  }
  
  .trailer-details ul {
    list-style: none; /* Remove bullet points */
    padding-left: 0; /* Remove default padding */
  }
  
  .trailer-details li::marker {
    content: ''; /* Remove default markers */
  }  
  
  .specifications-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    text-align: left; /* Keep table content aligned left */
  }
  
  .specifications-table th,
  .specifications-table td {
    border: 1px solid var(--black);
    padding: 10px;
  }
  
  .specifications-table th {
    background-color: var(--tannish-green);
    color: var(--light-tan);
    text-align: left;
  }
  
  .models-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Center the model cards */
  }

 
  
  .model-card {
    background-color: var(--pleasant-beige);
    padding: 15px;
    border-radius: 10px;
    flex: 1;
    min-width: 200px;
    text-align: center;
  }
  
  /* Carousel styles */
.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
}

.trailer-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.carousel-arrow {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  border-radius: 50%;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.carousel-arrow:hover {
  background: rgba(0, 0, 0, 0.7);
}

.no-bullets {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.no-bullets li {
  text-indent: 0;
}

/* Colors Section */
.colors-section {
  text-align: center;
  margin-top: 40px; /* Separate it a bit from the sections above */
}

.colors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid */
  gap: 20px;
}

.color-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.color-swatch-rectangle {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid var(--black);
}

.color-card p {
  margin: 0;
  font-size: 14px;
  color: var(--black);
}

/* Features and Options Section */
.features-options-section {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 40px;
}

.features-section, .options-section {
  flex: 1;
  text-align: left; /* Align text to the left */
}

.features-section ul, .options-section ul {
  padding-left: 20px;
}

.features-section ul li, .options-section ul li {
  text-indent: 0;
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .features-options-section {
    flex-direction: column;
    align-items: center;
  }
}

/* Center the headers in their respective sections */
.features-section h3, .options-section h3 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px; /* Adds a bit of space below the header */
}

.large-header {
  font-size: 24px;
  font-weight: bold;
  color: var(--dark-green); /* Optional: Adjust the color */
}