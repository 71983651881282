/* src/TrailersByCategory.css */

.trailers-container {
  max-width: 80%; /* Use a percentage to allow dynamic adjustment */
  margin: 0 auto;
  padding: 20px 40px;
  opacity: 0;
  transform: translateY(20px); /* Slight initial movement */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Fade-in transition */
}

/* This class will be added to the container after mounting */
.fade-in {
  opacity: 1;
  transform: translateY(0); /* Reset the movement */
}

.category-title {
  font-size: 48px; /* Larger size for more emphasis */
  color: var(--dark-green);
  margin-bottom: 30px;
  text-align: center;
  font-family: 'Cinzel', serif;
  font-weight: 700;
  border-bottom: 3px solid var(--dark-green); /* Add bottom border */
  padding-bottom: 10px;
}

.back-button-container {
  text-align: center;
  margin-bottom: 20px;
}

.back-button {
  display: inline-block;
  color: var(--black);
  text-decoration: none;
  padding: 10px 20px;
  background-color: var(--tannish-green);
  border-radius: 5px;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: var(--dark-green);
  color: var(--light-tan);
}

/* Grid for trailers */
.trailers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Ensure consistency */
  gap: 20px;
  justify-items: center;
}
