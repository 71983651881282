/* src/Home.css */

/* Container for the static background */
.home-container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;  /* Ensures the background covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents background repetition */
  background-image: url('./images/trailer-background.jpg'); /* Add your single background image here */
  overflow: hidden; /* Prevents content overflow */
}

/* Remove the crossfade-specific background layers and transitions */

/* Content overlay styles */
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  color: var(--white);
  width: 100%;
  z-index: 2; /* Ensure the content is above the background */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.overlay.hidden {
  opacity: 0;
  transform: translateY(100px);
  pointer-events: none;
}

.home-logo {
  width: 150px;
  margin-bottom: 20px;
}

.welcome-text {
  font-family: 'Cinzel', serif;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}

.home-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: inline-flex;
  gap: 20px;
}

.home-nav ul li a {
  font-family: 'Cinzel', serif;
  font-weight: 700;
  font-size: 24px;
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s;
}

.home-nav ul li a:hover {
  color: var(--accent-orange);
}

.toggle-arrow {
  font-size: 32px;
  color: var(--white);
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .welcome-text {
    font-size: 36px;
  }

  .home-nav ul li a {
    font-size: 18px;
  }
}
