/* src/Footer.css */

.site-footer {
  background-color: var(--tannish-green);
  padding: 20px;
  text-align: center;
}

.footer-container p {
  margin: 0;
  color: var(--black);
}
