/* src/Header.css */

.site-header {
  background-color: var(--tannish-green);
  padding: 10px 20px;
}

.header-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.header-left {
  grid-column: 1 / 2;
  display: flex;
  justify-content: flex-start;
}

.header-center {
  grid-column: 2 / 3;
  text-align: center;
}

.header-right {
  grid-column: 3 / 4;
  display: flex;
  justify-content: flex-end;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  width: 70px;
  height: 70px;
}

.company-name {
  font-family: 'Cinzel', serif;
  font-weight: 700;
  font-size: 32px;
  margin: 0;
}

.main-nav {
  margin-top: 5px;
}

.main-nav ul {
  list-style-type: none;
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.main-nav ul li {
  margin: 0 10px;
}

.menu-link {
  font-family: 'Cinzel', serif;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  color: var(--black);
}

.menu-link:hover {
  color: var(--dark-green); /* Use a subtle dark green for hover */
  text-decoration: underline;
}

.contact-info {
  font-size: 20px;
  color: var(--black);
}

.phone-number {
  font-weight: bold;
  font-size: 24px; /* Make the phone number larger */
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .header-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header-left, .header-right {
    display: none; /* Hide unnecessary elements for mobile */
  }

  .logo {
    width: 80px; /* Slightly increase the logo size for better visibility on mobile */
    height: 80px;
    margin-bottom: 10px; /* Add space between the logo and company name */
  }

  .company-name {
    font-size: 28px; /* Slightly smaller font for company name on mobile */
    margin-bottom: 15px; /* Add space between company name and menu */
  }

  .main-nav ul {
    flex-direction: column; /* Stack menu items vertically */
    margin-top: 10px;
  }

  .main-nav ul li {
    margin: 10px 0; /* Add space between menu items */
  }

  .menu-link {
    font-size: 20px; /* Slightly larger font for better tapability on mobile */
  }
}
